import React from "react"
import Image from "gatsby-image/withIEPolyfill"

const Servico = props => {
  return (
    <div
      className={`flex content-center flex-wrap ${
        props.reverse ? "flex-row-reverse" : ""
      } text-gray-700 container mx-auto`}
    >
      <div className="w-full sm:w-1/2 bg-gray-500 text-center">
        <Image
          className="lazyload "
          objectFit="contain"
          fluid={props.image}
          alt="Eletrodomesticos"
        />
      </div>
      <div className="w-full sm:w-1/2 bg-gray-300 p-6 sm:p-2 px-6 text-center flex justify-center items-center inner-border">
        <div>
          <h2 className="text-xl md:text-2xl font-bold mb-2 md:mb-4">
            {props.title}
          </h2>
          <ul>
            {props.items.map((item, index) => (
              <li className="text-lg md:text-xl" key={index}>
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Servico
