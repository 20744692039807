import React from "react"
import Layout from "../components/layout"
import ServicoList from "../components/servico-list"
import SEO from "../components/seo"
import AutorizadasList from "../components/autorizadas-list"
import { Link } from "gatsby"

const Servicos = () => {
  return (
    <Layout>
      <SEO title="Serviços Dentro e Fora da Garantia Eletrodomésticos Cedilar" />
      <div className="container mx-auto py-6 px-4 sm:px-0">
        <h1 className="text-gray-700 text-xl sm:text-2xl leading-tight font-bold">
          Especializamos em{" "}
          <Link
            to="/"
            aria-label="Ir a Página Inicial"
            className="text-blue-600"
          >
            concerto dentro e fora de garantia
          </Link>{" "}
          desses e muitos mais produtos:
        </h1>
      </div>
      <ServicoList />
      <AutorizadasList />
    </Layout>
  )
}

export default Servicos
