import React from "react"
import Image from "gatsby-image/withIEPolyfill"
import { useStaticQuery, graphql } from "gatsby"

const AutorizadasList = () => {
  const data = useStaticQuery(graphql`
    {
      eletrodomesticosCozinha: allFile(
        filter: { relativePath: { eq: "Eletrodomesticos-Cozinha.jpg" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
      lorenzettiLogo: allFile(
        filter: { relativePath: { eq: "marcas/Lorenzetti.jpeg" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
      mondialLogo: allFile(
        filter: { relativePath: { eq: "marcas/Mondial.png" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
      cardalLogo: allFile(
        filter: { relativePath: { eq: "marcas/Cardal.png" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
      hydraLogo: allFile(filter: { relativePath: { eq: "marcas/Hydra.png" } }) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
      philipsWalitaLogo: allFile(
        filter: { relativePath: { eq: "marcas/Philips-Walita.png" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
      coronaLogo: allFile(
        filter: { relativePath: { eq: "marcas/corona.jpg" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
      sintexLogo: allFile(
        filter: { relativePath: { eq: "marcas/Sintex.jpg" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className="m-6 content-center text-center">
      <h2 className="font-bold text-gray-700 text-2xl mb-4 px-4 md:px-0 leading-tight">
        Somos Autorizadas Pelas Seguintes Marcas:
      </h2>
      <div className="flex gap-4 justify-around items-center flex-wrap">
        <Image
          className="w-40 lazyload"
          objectFit="contain"
          fluid={data?.mondialLogo?.edges[0]?.node?.childImageSharp?.fluid}
          alt="Mondial"
        />
        <Image
          className="w-40 lazyload"
          objectFit="contain"
          fluid={data?.cardalLogo?.edges[0]?.node?.childImageSharp?.fluid}
          alt="Cardal"
        />
        <Image
          className="w-40 lazyload"
          objectFit="contain"
          fluid={data?.sintexLogo?.edges[0]?.node?.childImageSharp?.fluid}
          alt="Sintex"
        />
        <Image
          className="w-40 lazyload"
          objectFit="contain"
          fluid={data?.lorenzettiLogo?.edges[0]?.node?.childImageSharp?.fluid}
          alt="Lorenzetti"
        />
        <Image
          className="w-40 lazyload"
          objectFit="contain"
          fluid={data?.hydraLogo?.edges[0]?.node?.childImageSharp?.fluid}
          alt="Hydra"
        />
        <Image
          className="w-40 lazyload"
          objectFit="contain"
          fluid={data?.coronaLogo?.edges[0]?.node?.childImageSharp?.fluid}
          alt="Corona"
        />
        <Image
          className="w-40 lazyload"
          objectFit="contain"
          fluid={
            data?.philipsWalitaLogo?.edges[0]?.node?.childImageSharp?.fluid
          }
          alt="Philips-Walita"
        />
      </div>
    </div>
  )
}

export default AutorizadasList
