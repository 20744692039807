import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Servico from "./servico"

const ServicoList = () => {
  const data = useStaticQuery(graphql`
    {
      eletrodomesticos: allFile(
        filter: { relativePath: { eq: "servicos/eletrodomesticos.jpeg" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
      banho: allFile(filter: { relativePath: { eq: "servicos/banho.jpeg" } }) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
      ferramentas: allFile(
        filter: { relativePath: { eq: "servicos/ferramentas.jpeg" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
      cuidadosPessoais: allFile(
        filter: { relativePath: { eq: "servicos/cuidado-pessoais.jpeg" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
      ventilacao: allFile(
        filter: { relativePath: { eq: "servicos/ventilacao.jpeg" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <Servico
        image={data?.eletrodomesticos?.edges[0]?.node?.childImageSharp?.fluid}
        title="Eletrodomésticos"
        items={[
          "Air Fryers",
          "Panela elétrica de pressão",
          "Panela elétrica de arroz",
          "Liquidificadores",
        ]}
      />
      <Servico
        image={data?.banho?.edges[0]?.node?.childImageSharp?.fluid}
        title="Banho"
        reverse={true}
        items={[
          "Duchas",
          "Aquecedores centrais",
          "Aquecedores individuais",
          "Hidromassagem",
        ]}
      />
      <Servico
        image={data?.ventilacao?.edges[0]?.node?.childImageSharp?.fluid}
        title="Ventilação"
        items={[
          "Ventiladores",
          "Aquecedores de ar",
          "Aquecedores halógeno",
          "Aquecedor a Óleo",
        ]}
      />
      <Servico
        image={data?.cuidadosPessoais?.edges[0]?.node?.childImageSharp?.fluid}
        title="Cuidados Pessoais"
        reverse={true}
        items={[
          "Barbeadores elétricos",
          "Depiladores elétricos",
          "Secadores de cabelo",
        ]}
      />
      <Servico
        image={data?.ferramentas?.edges[0]?.node?.childImageSharp?.fluid}
        title="Ferramentas"
        items={[
          "Serra circular",
          "Serra Tico-Tico",
          "Furadeiras",
          "Parafuseiras",
        ]}
      />
    </>
  )
}

export default ServicoList
